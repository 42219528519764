/*! _boxes.scss | Bulkit | CSS Ninja */

/* ==========================================================================
Box styles
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Simple icon box
1. Diamond icon box
2. Square icon box
3. Long shadow icon boxes
4. Simple counter
5. Card counter
6. Icon counter
7. Parallax counter
8. Team V1
9. Team V4
10. Team V5
11. Team V6
12. Client grids
13. Centered images carousel
14. Multiple images carousel
15. Single image carousel
16. Custome slick navigation arrows
=============================================================================
***/

//Vendor prefix Mixin
@mixin prefix($prop, $val) {
    @each $prefix in '-webkit-', '-moz-', '-ms-', '' {
        #{$prefix}#{$prop}: $val;
    }
}

/* ==========================================================================
0. Simple icon box
========================================================================== */
.icon-box {
    //icon
    .box-icon {
        color: $placeholder;
        font-size: 2.8rem;
        margin-bottom: 1rem;
    }
    //title
    .box-title {
        color: $blue-grey;
        margin-bottom: 10px;
        font-weight: 500;
    }
    //text
    .box-text {
        color: $title-grey;
        font-size: 13px;
    }
    //Colors
    &.primary {
        .box-icon {
            color: $primary; 
        }
        .box-title {
            color: $primary;
        }
    }
    &.secondary {
        .box-icon {
            color: $secondary; 
        }
        .box-title {
            color: $secondary;
        }
    }
    &.accent {
        .box-icon {
            color: $accent; 
        }
        .box-title {
            color: $accent;
        }
    }
    &.info {
        .box-icon {
            color: $blue; 
        }
        .box-title {
            color: $blue;
        }
    }
    &.success {
        .box-icon {
            color: $green; 
        }
        .box-title {
            color: $green;
        }
    }
    &.warning {
        .box-icon {
            color: $orange; 
        }
        .box-title {
            color: $orange;
        }
    }
    &.danger {
        .box-icon {
            color: $red; 
        }
        .box-title {
            color: $red;
        }
    }
    //Compact style
    &.compact {
        //icon
        .box-icon {
            color: $white;
            font-size: 3rem;
            font-weight: bolder;
            transform: rotate(45deg);
            i {
                padding: 1.4rem;
                background: $accent;
                border-radius: 5px;

            }
        }
        //text
        .box-text {
            padding: 0 40px 0 40px;
        }
    }
}

/* ==========================================================================
1. Diamond icon box
========================================================================== */
.diamond-box {
    text-align: center;
    //wrapper
    .diamond-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    //diamond
    .diamond {
        width: 60px;
        height: 60px;
        background-color: $muted-grey;
        position: relative;
        border-radius: 4px;
        transform: rotate(45deg);
        margin-bottom: 1.5rem;
        i {
            font-size: 36px;
            position: absolute;
            color: $white;
            left: 11px;
            top: 10px;
            transform: rotate(-45deg);
        }
    }
    //title
    .box-title {
        color: $blue-grey;
        margin-bottom: 10px;
        font-weight: 500;
    }
    //text
    .box-text {
        color: $title-grey;
        font-size: 13px;
    }
    //colors
    &.primary {
        .diamond {
            background-color: $primary;
            box-shadow: $primary-box-shadow; 
        }
        .box-title {
            color: $primary;
        }
    }
    &.secondary {
        .diamond {
            background-color: $secondary;
            box-shadow: $secondary-box-shadow; 
        }
        .box-title {
            color: $secondary;
        }
    }
    &.accent {
        .diamond {
            background-color: $accent;
            box-shadow: $accent-box-shadow; 
        }
        .box-title {
            color: $accent;
        }
    }
    &.info {
        .diamond {
            background-color: $blue;
            box-shadow: $blue-box-shadow; 
        }
        .box-title {
            color: $blue;
        }
    }
    &.success {
        .diamond {
            background-color: $green;
            box-shadow: $green-box-shadow; 
        }
        .box-title {
            color: $green;
        }
    }
    &.warning {
        .diamond {
            background-color: $orange;
            box-shadow: $orange-box-shadow; 
        }
        .box-title {
            color: $orange;
        }
    }
    &.danger {
        .diamond {
            background-color: $red;
            box-shadow: $red-box-shadow; 
        }
        .box-title {
            color: $red;
        }
    }
}

/* ==========================================================================
2. Square icon box
========================================================================== */
.square-icon-box {
    text-align: center;
    &:hover .icon-box-wrapper .icon-box:after {
        transform: scale(1);
    }
    //rounded style
    &.rounded {
        .icon-box {
            border-radius: 100px !important;
            &:after {
                border-radius: 100px !important;
            }
        }
    }
    //wrapper
    .icon-box-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
        //icon
        .icon-box {
            width: 60px;
            height: 60px;
            background-color: $muted-grey;
            position: relative;
            border-radius: 4px;
            //margin-bottom: 1.5rem;
            cursor: pointer;
            i {
                font-size: 28px;
                position: absolute;
                color: $white;
                left: 16px;
                top: 16px;
            }
            &:after {
                width: 76px;
                height: 76px;
                content:"";
                display:block;
                position:absolute;
                z-index:1;
                top:-8px;
                left:-8px;
                opacity: 0.8;
                border-radius: 4px;
                transform: scale(0.8);
                transition: all 0.3s;      
            }
        }
    }
    //title
    .box-title {
        color: $blue-grey;
        padding: 5px;
        font-weight: 500;
    }
    //text
    .box-text {
        color: $title-grey;
        padding: 0 10px;
        font-size: 13px;
    }
    //colors
    &.primary {
        .icon-box {
            background-color: $primary;
            box-shadow: $primary-box-shadow;
            &:after {
                border:2px solid $primary;
            }
        }
        .box-title {
            color: $primary;
        }
    }
    &.secondary {
        .icon-box {
            background-color: $secondary;
            box-shadow: $secondary-box-shadow;
            &:after {
                border:2px solid $secondary;
            }
        }
        .box-title {
            color: $secondary;
        }
    }
    &.accent {
        .icon-box {
            background-color: $accent;
            box-shadow: $accent-box-shadow;
            &:after {
                border:2px solid $accent;
            }
        }
        .box-title {
            color: $accent;
        }
    }
    &.info {
        .icon-box {
            background-color: $blue;
            box-shadow: $blue-box-shadow;
            &:after {
                border:2px solid $blue;
            }
        }
        .box-title {
            color: $blue;
        }
    }
    &.success {
        .icon-box {
            background-color: $green;
            box-shadow: $green-box-shadow;
            &:after {
                border:2px solid $green;
            }
        }
        .box-title {
            color: $green;
        }
    }
    &.warning {
        .icon-box {
            background-color: $orange;
            box-shadow: $orange-box-shadow;
            &:after {
                border:2px solid $orange;
            }
        }
        .box-title {
            color: $orange;
        }
    }
    &.danger {
        .icon-box {
            background-color: $red;
            box-shadow: $red-box-shadow;
            &:after {
                border:2px solid $red;
            }
        }
        .box-title {
            color: $red;
        }
    }
}

/* ==========================================================================
3. Long shadow icon boxes
========================================================================== */

//Variables
$icon-color: $white;     
$primary-box-background: $primary;
$secondary-box-background: $secondary;
$accent-box-background: $accent;
$info-box-background: $blue;
$success-box-background: $green;
$warning-box-background: $orange;
$danger-box-background: $red;
$primary-shadow-color: darken($primary,15%);  
$secondary-shadow-color: darken($secondary,15%); 
$accent-shadow-color: darken($accent,15%); 
$info-shadow-color: darken($blue,15%); 
$success-shadow-color: darken($green,15%); 
$warning-shadow-color: darken($orange,15%); 
$danger-shadow-color: darken($red,15%); 
$box-primary-shadow: $primary-box-shadow;
$box-secondary-shadow: $secondary-box-shadow;
$box-accent-shadow: $accent-box-shadow;
$box-info-shadow: $blue-box-shadow;
$box-success-shadow: $green-box-shadow;
$box-warning-shadow: $orange-box-shadow;
$box-danger-shadow: $red-box-shadow;
$length: 75;

//long shadow mixin
@mixin long-shadow ($shadow-color, $length) {
    $list: ();
    @for $i from 1 through $length {
        $list: append($list, $shadow-color $i+px $i+px, comma);
    }
    text-shadow: $list;
}

//long shadow
.shadow-icon-box span {
    display: block;
    position: absolute;
    height: $length+px;
    width: $length+px;
    color: $icon-color;
    @include long-shadow ($primary-shadow-color, $length);
}

//icon-box
.shadow-icon-box {
    position: relative;
    text-align: center;
    overflow: hidden;
    margin: 0 auto;
    width: $length+px;
    height: $length+px;
    font-size: $length/3+px;
    line-height: $length+px;
    background-color: $primary-box-background;
    @include prefix(border-radius, 10px);
    @include prefix(box-shadow, $box-primary-shadow);
    //rounded style
    &.rounded {
        @include prefix(border-radius, 100px);
    }
    //Colors
    &.is-secondary {
        background-color: $secondary-box-background;
        @include prefix(box-shadow, $box-secondary-shadow);
        span {
            @include long-shadow ($secondary-shadow-color, $length);
        }
    }
    &.is-accent {
        background-color: $accent-box-background;
        @include prefix(box-shadow, $box-accent-shadow);
        span {
            @include long-shadow ($accent-shadow-color, $length);
        }
    }
    &.is-info {
        background-color: $info-box-background;
        @include prefix(box-shadow, $box-info-shadow);
        span {
            @include long-shadow ($info-shadow-color, $length);
        }
    }
    &.is-success {
        background-color: $success-box-background;
        @include prefix(box-shadow, $box-success-shadow);
        span {
            @include long-shadow ($success-shadow-color, $length);
        }
    }
    &.is-warning {
        background-color: $warning-box-background;
        @include prefix(box-shadow, $box-warning-shadow);
        span {
            @include long-shadow ($warning-shadow-color, $length);
        }
    }
    &.is-danger {
        background-color: $danger-box-background;
        @include prefix(box-shadow, $box-danger-shadow);
        span {
            @include long-shadow ($danger-shadow-color, $length);
        }
    }
}
//Long shadow box title
.shadow-title {
    padding-top: 10px;
    color: $blue-grey;
    font-weight: 500;
}

//Long shadow box text
.shadow-text {
    color: $title-grey;
    font-size: 13px;
}

/* ==========================================================================
4. Simple counter
========================================================================== */

.simple-counter {
    position: relative;
    //number
    .counter-number {
        font-size: 3rem;
        color: $blue-grey;
        z-index: 99;
        &.is-bold {
            font-weight: 700 !important;
        }
    }
    //text
    .counter-text {
        text-transform: uppercase;
        font-family: 'Nexa Light', sans-serif;
        font-size: 1rem;
        font-weight: 900;
        color: $primary !important;
    }
    //background icon
    .background-icon {
        position: absolute;
        font-size: 5rem;
        top: -10px !important;
        left: 0;
        right: 0;
        color: $muted-grey;
        opacity: 0.1;
        z-index: 0;
    }
}

/* ==========================================================================
5. Card counter
========================================================================== */

.card-counter {
    .flex-card {
        padding: 20px;
        overflow: visible;
        &:hover {
            .round-icon i {
                transform: rotate(360deg) scale(1.05);
                background: $accent;
                box-shadow: $accent-box-shadow;
            }  
            .counter-text {
                color: $accent;
            }
        }
    }
    //icon
    .round-icon i {
        font-size: 2rem;
        padding: 22px;
        color: $white;
        background: $primary;
        box-shadow: $primary-box-shadow;
        border-radius: 100px;
        margin-top: -40px;
        transition: all 0.4s;
    }
    //number
    .counter-number {
        font-weight: bolder;
        font-size: 2.6rem;
        color: $blue-grey;
    }
    //text
    .counter-text {
        color: $primary;
        font-weight: 600;
        transition: all 0.4s;
        font-size: 1.1rem;
    }
}

/* ==========================================================================
6. Icon counter
========================================================================== */

.icon-counter {
    //icon
    .counter-icon i {
        font-size: 3.4rem;
        color: $primary;
    }
    //number
    .counter-number {
        font-weight: bolder;
        font-size: 3rem;
        color: $blue-grey;
    }
    //text
    .counter-text {
        color: $primary;
        font-size: 1.1rem;
        font-weight: 600;
        transition: all 0.4s;
    }
}

/* ==========================================================================
7. Parallax counter
========================================================================== */

.parallax-counter {
    //icon
    .counter-icon i {
        font-size: 3.4rem;
        color: $white;
    }
    //number
    .counter-number {
        font-weight: bolder;
        font-size: 3rem;
        color: $white;
    }
    //text
    .counter-text {
        color: $white;
        font-family: 'Nexa Light', sans-serif;
        font-size: 1.1rem;
        font-weight: 500;
        padding: 0 10px;
        transition: all 0.4s;
    }
}

/* ==========================================================================
8. Team V1
========================================================================== */

.team-classic {
    //member image
    .team-member {
        position: relative;
        img {
            height: 100%;
            width: 100%;
            display: block;
        }
        //overlay
        .item-overlay {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background: rgba(0,0,0,0);
            transition: all 0.5s;
            overflow: hidden;
            .overlay-quote {
                opacity: 0;
                opacity: 0;
                color: $white;
                position: absolute;
                top: 55%;
                left: 0;
                padding: 5%;
                overflow: hidden;
                width: 100%;
                z-index: 2;
                transform: translateY(100%);
                -webkit-transition: all .3s;
                -moz-transition: all .3s;
                -o-transition: all .3s;
                transition: all .3s;
                .quote-text i {
                    font-size: 18px;
                }
                .social-links {
                    padding: 20px 0;
                    -webkit-transition: all .3s;
                    -moz-transition: all .3s;
                    -o-transition: all .3s;
                    transition: all .3s;
                    span a {
                        margin: 0 10px 0 10px;
                        color: $white;
                        &:hover {
                            opacity: 1;
                        }
                        i {
                            font-size: 1.4em;
                            padding: 15px;
                            border-radius: 100px;
                            border: 2px dashed $white;
                            transition: all 0.1;
                        }
                    }
                    span:first-child a i:hover {
                        background: $twitter;
                        border: 2px solid $twitter;
                    }
                    span:nth-child(2) a i:hover {
                        background: $linkedin;
                        border: 2px solid $linkedin;
                    }
                    span:last-child a i:hover {
                        background: $dribbble;
                        border: 2px solid $dribbble;
                    }
                }
            }
        }
        &:hover .item-overlay  {
            background: rgba(0,0,0,0.7);
        }
        &:hover .overlay-quote {
            opacity: 1;
            transform: translateY(-100%);
        }
    }
    //meta
    .member-name {
        font-family: 'Nexa Light', sans-serif;
        text-transform: uppercase;
        font-size: 1.3rem;
        .last-name {
            font-weight: bolder;
            font-family: 'Nexa Bold', sans-serif;
        }
        .position {
            font-size: 0.9rem;
            color: $primary;
            font-family: 'Nexa Light', sans-serif;
        }
    }
    //circled social icons
    &.circled {
        .social-links {
            span:first-child a i:hover {
                background: none !important;
                border: 2px solid $twitter;
                color: $twitter;
            }
            span:nth-child(2) a i:hover {
                background: none !important;
                border: 2px solid $linkedin;
                color: $linkedin;
            }
            span:last-child a i:hover {
                background: none !important;
                border: 2px solid $dribbble;
                color: $dribbble;
            }
        }
    }
    //Square social icons
    &.squared {
        .social-links {
            span a i {
                border-radius: 3px !important;
            }
            span:first-child a i:hover {
                background: none !important;
                border: 2px solid $twitter;
                color: $twitter;
            }
            span:nth-child(2) a i:hover {
                background: none !important;
                border: 2px solid $linkedin;
                color: $linkedin;
            }
            span:last-child a i:hover {
                background: none !important;
                border: 2px solid $dribbble;
                color: $dribbble;
            }
        }
    }
}

/* ==========================================================================
9. Team V4
========================================================================== */
.modern-team {
    position: relative;
    z-index: 1;
    overflow: hidden;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    //Team member
    .modern-team-item {
        .item-wrapper {
            position: relative;
        }
        .item-img {
            position: relative;
            overflow: hidden;
            &:before {
                content: "";
                background-color: rgba(72, 27, 174, .7);
                position: absolute;
                top: 50%;
                left: 50%;
                z-index: 2;
            }
            .member-avatar {
                display: block;
                max-width: 100%;
                position: relative;
                z-index: 1;
            }
        }
        //Overlay
        .overlay-wrapper {
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            opacity: 0;
            will-change: opacity;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 10px;
            padding-right: 10px;
            text-align: center;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 3;
            .social {
                box-sizing: border-box;
                width: 42px;
                height: 42px;
                padding: 10px;
                background-color: #fff;
                border-radius: 100%;
                margin: 5px;
                position: relative;
                display: inline-block;
                vertical-align: middle;
                i {
                    position: relative;
                    font-size: 1.6rem;
                }
            }
        }
        //Meta
        .member-info {
            padding: 10px 20px;
            .member-name {
                font-family: 'Nexa Light', sans-serif;
                font-size: 1.2em;
                margin-top: 0;
                margin-bottom: 0;
                text-transform: uppercase;
                strong {
                    font-family: 'Nexa Bold', sans-serif;
                }
            }
            .member-position {
                display: block;
                margin: .5em 0;
                font-family: 'Nexa Light', sans-serif;
                font-size: .8em;
                text-transform: uppercase;
                color: $primary;
            }
        }
        &:hover .overlay-wrapper {
            opacity: 1;
            height: 100%;
            transition: opacity .4s cubic-bezier(0.71, 0.05, 0.29, 0.9) .2s;
        }
        //Circle mask overlay
        &.circle-mask .item-img:before {
            width: 0;
            height: 0;
            padding: 25%;
            border-radius: 50%;
            transition: transform .3s ease, opacity .3s ease-out;
            will-change: opacity, transform;
            opacity: 0;
            transform: translate(-50%, -50%) scale(0);
        }
        &.circle-mask:hover .item-img:before {
            opacity: 1;
            transform: translate(-50%, -50%) scale(4);
            transition-duration: .6s;
        }
        //Curtain mask overlay
        &.curtain-mask .item-img:before {
            width: 100%;
            height: 100%;
            opacity: 0;
            -webkit-clip-path: polygon(50% 10%, 15% 90%, 85% 90%);
            clip-path: polygon(50% 10%, 15% 90%, 85% 90%);
            transition: transform, opacity;
            transition-duration: .2s, .4s;
            transition-delay: .4s, 0s;
            transition-timing-function: ease-out;
            will-change: transform, opacity;
            transform: translate(-50%, -50%) scale(1);
        }
        &.curtain-mask:hover .item-img:before {
            opacity: 1;
            transform: translate(-50%, -50%) scale(5);
            transition-delay: .1s, 0s;
            transition-duration: .4s;
        }
        //zoom effect
        &.zoom-effect .member-avatar {
            transition: transform .4s cubic-bezier(0.71, 0.05, 0.29, 0.9);
            will-change: transform;
            transform: scale(1);
        }
        &.zoom-effect:hover .member-avatar {
            transform: scale(1.2);
        }
        //rotate zoom effect
        &.rotate-zoom-effect .member-avatar {
            transition: transform .4s cubic-bezier(0.71, 0.05, 0.29, 0.9);
            will-change: transform;
            transform: scale(1) rotate(0);
        }
        &.rotate-zoom-effect:hover .member-avatar {
            transform: scale(1.2) rotate(5deg);
        }
        //zoom slide effect
        &.zoom-slide-effect .member-avatar {
            transition: transform .4s cubic-bezier(0.71, 0.05, 0.29, 0.9);
            will-change: transform;
            transform: scale(1) translate(0, 0);
        }
        &.zoom-slide-effect:hover .member-avatar {
            transform: scale(1.2) translate(4%, 4%);
        }
    }
}

/* ==========================================================================
10. Team V5
========================================================================== */

.card-team {
    //card
    .card-team-item {
        text-align: center;
        height: 270px;
        .flex-card {
            height: 270px;
            margin-bottom: 0;
            border-radius: 0;
            &:hover {
                transform: scale(1.05);
                z-index: 1000;
            }
        }
        //avatar
        .team-avatar {
            width: 100%;
            img {
                width: 70px;
                height: 70px;
                border-radius: 100px;
            }
        }
        //meta
        .member-id .name {
            font-family: 'Nexa Bold', sans-serif;
            margin-top: 15px;
            font-size: 1.4rem;
            font-weight: 600;
            color: $blue-grey;
        }
        .member-id .position {
            font-family: 'Nexa Light', sans-serif;
            text-transform: uppercase;
            margin-top: 5px;
            font-size: .8rem;
            font-weight: 400;
            color: $primary;
        }
        //social icons
        .card-social-links {
            padding-top: 35px;
            margin-bottom: 20px;
            a {
                color: $muted-grey;
                margin: 0 10px 0 10px;
                &:nth-child(2) i {
                    transition-delay: 0.05s;
                }
                &:last-child i {
                    transition-delay: 0.10s;
                }
                &:hover {
                    color: $primary;
                }
            }
            a i {
                font-size: 1.3rem;
                transform: translateY(300px);
                -ms-transition: all 0.35s;
                -moz-transition: all 0.35s;
                -webkit-transition: all 0.35s;
                transition: all 0.35s;

            }
        }
        //Active state
        &:hover .card-social-links a i {
            transform: translateY(0);
        }
    }
}

/* ==========================================================================
11. Team V6
========================================================================== */

.flip-boxes {
    //Flip card
    .flip-box {
        perspective:500px;
        min-height: 300px;
        cursor: pointer;
    }
    //wrapper
    .flip-box-wrapper {
        position: relative;
        transition:all .5s ease-in-out;
        transform-style:preserve-3d;
        border: 1px solid $fade-grey;
        box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
        div {
            position: relative;
            top:0;
            left:0;
            width: 100%;
            height:100%;
            text-align:center;
            background: $white;
        }
        //front
        .front {
            z-index:2;
            background-color: $white;
            backface-visibility: hidden;
            box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
            img {
                height: 100%;
                width: 100%;
                display: block;
            }
        }
        //back
        .back {
            z-index:1;
            background: $white;
            opacity: 1;
            transform: rotateY(180deg);
            backface-visibility: hidden;
            box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
            .flip-name {
                background: $white;
                position: absolute;
                top: -22rem !important;
                left: 0;
                min-height: 25px;
                font-size: 1.4rem;
                text-transform: uppercase;
                span {
                    font-size: 0.9rem;
                    text-transform: uppercase;
                    color: $primary;
                }
            }
            //social icons
            .flip-social {
                position: absolute;
                top: -16rem;
                a {
                    color: $muted-grey;
                    font-size: 1.4rem;
                    margin: 0 10px 0 10px;
                    &:hover {
                        color: $primary;
                    }
                }
            }
        }
        //Active state
        &:hover {
            transform: rotateY(180deg);
        }
    }
}

/* ==========================================================================
12. Client grids
========================================================================== */

.grid-clients {
    .client {
        max-height: 70px;
    }
    //3 columns grid
    &.three-grid {
        .column:nth-child(2), .column:nth-child(3) {
            border-right: 1px solid $fade-grey;
        }
        .is-separator {
            border-top: 1px solid $fade-grey;
        }
    }
    //4 columns grid
    &.four-grid {
        .column:nth-child(2), .column:nth-child(3) {
            border-right: 1px solid $fade-grey;
        }
        .column:nth-child(5) {
            border-left: 1px solid $fade-grey;
        }
        .is-separator {
            border-top: 1px solid $fade-grey;
        }
    }
    //5 columns grid
    &.five-grid {
        .column:nth-child(2), .column:nth-child(3), .column:nth-child(4) {
            border-right: 1px solid $fade-grey;
        }
        .column:nth-child(5), .column:nth-child(6) {
            border-left: 1px solid $fade-grey;
        }
        .is-separator {
            border-top: 1px solid $fade-grey;
        }
    }
    a {
        display: flex;
        justify-content: center;
    }
}

/* ==========================================================================
13. Centered images carousel
========================================================================== */

.image-carousel {
    text-align: center;
    max-width: 950px;
    margin: 0 auto;
    &:hover .slick-custom {
        opacity: 1;
    }
    //item
    .carousel-item {
        margin: 0 20px;
    }
    .image-wrapper {
        position: relative;
        .stack-logo {
            max-height: 130px;
        }
    }
    .slick-custom {
        opacity: 0;
        &.is-prev {
            left: -4%;
        }
        &.is-next {
            right: -4%;
        }
    }
    .slick-dots {
        bottom: -60px !important;
    }
    .slick-prev:before, .slick-next:before {
        color: $muted-grey;
    }
}

/* ==========================================================================
14. Multiple images carousel
========================================================================== */

.multiple-image-carousel {
    text-align: center;
    max-width: 950px;
    margin: 0 auto;
    &:hover .slick-custom {
        opacity: 1;
    }
    //item
    .carousel-item {
        margin: 0 20px;
    }
    .image-wrapper {
        position: relative;
        .stack-logo {
            max-height: 130px;
            margin: 0 auto;
        }
    }
    .slick-custom {
        opacity: 0;
        top: 35px;
    }
    .slick-dots {
        bottom: -60px !important;
    }
    .slick-prev:before, .slick-next:before {
        color: $muted-grey;
    }
}

/* ==========================================================================
15. Single image carousel
========================================================================== */

.single-image-carousel {
    text-align: center;
    max-width: 650px;
    margin: 0 auto;
    &:hover .slick-custom {
        opacity: 1;
    }
    //item
    .carousel-item {
        margin: 0 20px;
    }
    .image-wrapper {
        position: relative;
        &:hover .caption {
            transform: translateY(0);
        }
        img {
            width: 100%;
            height: 100%;
            min-height: 400px;
        }
        .caption {
            position: absolute;
            left: 0;
            width: 100%;
            bottom: 0;
            z-index: 99;
            padding: 20px;
            background: rgba(0,0,0,0.8);
            transform: translateY(120%);
            transition: transform 0.4s;
            span {
                color: white;
                font-size: 1.1rem;
            }
        }
    }
    .slick-custom {
        opacity: 0;
    }
    .slick-dots {
        bottom: -60px !important;
    }
    .slick-prev:before, .slick-next:before {
        color: $muted-grey;
    }
}

/* ==========================================================================
16. Custom slick carousel navigation arrows
========================================================================== */

.slick-custom {
    position: absolute;
    top: 41%;
    border: 1px solid $fade-grey;
    width: 50px;
    height: 50px;
    background: $white;
    border-radius: 100px;
    cursor: pointer;
    color: $blue-grey;
    transition: all 0.3s;
    z-index: 999;
    i {
        position: relative;
        top: 14px;
    }
    &:hover {
        transform: rotate(360deg);
        background: $primary;
        border: 1px solid $primary;
        color: $white;
        box-shadow: $primary-box-shadow;
    }
    //previous arrow
    &.is-prev {
        left: -6px;
        i {
            right: 2px; 
        }
    }
    //next arrow
    &.is-next {
        right: -6px;
        i {
            left: 2px;
        }
    }
}
